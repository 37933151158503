import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Header from '../components/Header';
import SEO from '../components/Seo';
import { Link } from 'gatsby';

const Gracias = () => (
  <LayoutEs>
    <Header />
    <SEO title="Gracias" 
          description="Gracias por contactar con Beköme Digital, agencia experta en Ecommerce Magento"
          robots="NOINDEX,NOFOLLOW" />
    <header className="masthead thankyou">
    <div className=" text-center">
      <h1 className="display-3">Gracias :)</h1>
      <h4 className="lead mt-5">
        Hemos recibido tu solicitud y en breve nos pondremos en contacto contigo.
      </h4>
      <p>
        <Link className="text-white text-uppercase" to="/" title="Inicio">
          Volver a la página de inicio
        </Link>
      </p>
    </div>
      <div className="overlay"></div>
    </header>

  </LayoutEs>
);

export default Gracias;
